import React from "react";
import TextInfo from "@/bit/components/components.text";
import IconGlyph from "@/bit/components/components.icon-glyph";

const CharacteristicItems = (props) => {
  const characKeys = Object.keys(props).filter((key) =>
    /^Charac-\d+$/.test(key)
  );
  const characValues = characKeys.map((key) => props[key]);

  if (
    !characValues ||
    !Array.isArray(characValues) ||
    (characValues && characValues.length <= 0)
  ) {
    return null;
  }
  const dataCy = `Characteristics-Item ${
    props?.index !== undefined ? `Characteristics-Item-${props.index}` : ""
  }`;

  return characValues.map((charac, index) => (
    <div
      className={`${
        props?.className ? props?.className : ""
      } main-characterisctics--row`}
      key={`main-characterisctics--${index}`}
      data-cy={dataCy}
    >
      {charac["Glyph-1"] && (
        <div
          className={`main-characterisctics--row--icon 
                        ${
                          charac["Text-2"]
                            ? "icon-padding"
                            : "icon-padding-default"
                        }
                            ${
                              props.layout === "tarifas"
                                ? "custom-min-width"
                                : ""
                            }`}
        >
          <IconGlyph
            {...charac["Glyph-1"]}
            dataCy={`MainCharacteristic-${index} Glyph`}
          />
        </div>
      )}

      <div
        className={`main-characterisctics--row--text ${
          charac["Glyph-1"] ? "" : "no-glyph"
        } ${!charac["Text-1"] ? "no-title" : ""}`}
      >
        {charac["Text-1"] && charac["Text-1"].text && (
          <TextInfo
            {...charac["Text-1"]}
            className="main-characterisctics--row--text-title"
            cyData={`MainCharacteristic-${index} Title`}
          />
        )}
        {charac["Text-2"] && (
          <TextInfo
            {...charac["Text-2"]}
            className={`main-characterisctics--row--text-subtitle ${
              !charac["Text-1"] ? "no-mt" : ""
            }`}
            cyData={`MainCharacteristic-${index} Subtitle`}
            text={charac["Text-2"].text}
          />
        )}
      </div>
      <style jsx>{`.main-characterisctics--row {
  display: flex;
  align-items: stretch;

  :global(.main-characterisctics--row--text) {
    margin: 0.4rem 0.5rem;
    margin-bottom: 0.2rem;

    &:global(.no-title) {
      @media screen and (min-width: 768px) {
        margin: 0.25rem 0.5rem;
      }
    }
    &:global(.no-glyph) {
      margin: 0;
    }
    :global(.main-characterisctics--row--text-subtitle) {
      margin-top: 0.25rem;
      line-height: 1.15;

      &:global(.no-mt) {
        margin-top: 0;
      }
    }
    :global(a) {
      color: #0079bb;
    }
    a:hover {
      color: #80cef9;
    }
  }
  .main-characterisctics--row--icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 20px;

    &.icon-padding {
      padding: 3px 0px;
      margin-top: 7px;
    }

    &.icon-padding-default {
      padding-top: 4px;
    }

    &.custom-min-width {
      min-width: 35px;
    }
  }
}
`}</style>
    </div>
  ));
};

export default CharacteristicItems;
