export const LABEL_TYPE = {
  PROMO: "promo",
  ACTIVE: "active",
  INACTIVE: "inactive",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  DISCOUNT: "discount",
  GIFT: "gift",
  SPENT: "spent",
  BF_BLUE1: "bf_blue1",
  BLUEA: "bluea",
};

export const LABEL_FUNC = {
  NONE: "",
  SAVING_VS_FREE: "ahorro-vs-libre",
};

export const LABEL_LAYOUT = {
  DEFAULT: "default",
  FULL: "full",
  FANTASY: 'fantasy',
};
