import React from "react";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import Button from "@/bit/components/components.button";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import ModalLink from "./link";

let FormC2C = null;

const C2CModule = (props) => {
  const dispatch = useDispatch();

  const openModal = () => {
    const device = getDeviceKindDependsOnViewPort();
    const MODAL_PROPS = {
      contentPosition:
        device !== "MOBILE" ? "center--center" : "center--bottom",
      opacityLayoutColor: "black05",
      inSceneEffect: "fadeInBottom",
      outSceneEffect: "fadeOutBottom",
      closeByClickingOutside: true,
      scrollAllowed: false,
    };
    FormC2C = dynamic(() => import("@/bit/components/components.forms.form-c2c"));
    dispatch(
      modalActions.addModal({
        id: "contratacion",
        type: "MODAL",
        elementProps: {},
        Element2Show: FormC2C,
        modalProps: MODAL_PROPS,
      })
    );
  };

  const taggingInfo = {
    componentName: "C2CModule",
    componentContent: props["Button-1"]?.label || "C2C button",
    componentVariation: "",
    componentPerso: "",
    componentProduct: "",
  };

  return (
    <>
      {props["Button-1"] && props["Button-1"].label && (
        <Button
          {...props["Button-1"]}
          onClick={openModal}
          taggingInfo={taggingInfo}
        />
      )}

      {props["Text-1"] &&
        props["Text-1"].text &&
        props["Text-2"] &&
        props["Text-2"].text && (
          <ModalLink {...props} onClick={openModal} taggingInfo={taggingInfo} />
        )}
    </>
  );
};

export default C2CModule;
