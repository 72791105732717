import React, { Component } from "react";
import CustomLink from "@/bit/components/components.custom-link";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import Glyph from "@/bit/components/components.icon-glyph";
import C2CModalComponent from "./c2c-contact-modal";

const COMPONENT_NAME = "SimplePhone";

const isProperrSchedule = () => {
  // const horaActual = new Date();
  const horaInicio = new Date();
  const horaFin = new Date();

  // Establecer la hora de inicio en 9:00 AM
  horaInicio.setHours(9);
  horaInicio.setMinutes(0);
  horaInicio.setSeconds(0);

  // Establecer la hora de finalización en 8:00 PM
  horaFin.setHours(24);
  horaFin.setMinutes(0);
  horaFin.setSeconds(0);

  // Verificar si la hora actual está dentro del rango
  return true;
};

class SimplePhone extends Component {
  constructor(props) {
    super(props);

    const schedule = true;
    this.state = {
      schedule,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.state.schedule = isProperrSchedule();
    }
  }

  render() {
    const { schedule } = this.state;
    if (!schedule) {
      return <div className="placeholder" />;
    }

    const {
      kind = "phone",
      custoObjectInfo,
      taggingInfo,
      belowPrice,
    } = this.props;

    let editModal = null;
    let className = "";
    let href = `tel:+34${this.props["Phone-1"].text}`;
    const taggingInfoPhones = {
      ...taggingInfo,
      componentName:
        (taggingInfo && taggingInfo.componentName) || COMPONENT_NAME,
      componentContent: `${this.props["Text-1"].text} ${this.props["Phone-1"].text}`,
      componentVariation: kind,
    };

    if (custoObjectInfo) {
      editModal = () => {
        custoObjectInfo.handler(custoObjectInfo.entryPoint);
      };
      className = "custo-editable";
      href = "javascript:void(0)";
    }

    const finalClassName = `simple-phone-component ${
      this.props.layout || "small-phone"
    }  ${this.props.display ? "" : "display-none-simplephone"}${className}`;

    return (
      <div className={finalClassName} onClick={editModal} data-cy="SimplePhone">
        <div className="mainPhoneCTA">
          {this.props["Text-1"] && this.props["Text-1"].text && (
            <>
              <div className="desktop">
                <TextInfo
                  {...this.props["Text-1"]}
                  cyData="SimplePhone Text-1"
                />
              </div>
              {this.props["TextMobile-1"] &&
                this.props["TextMobile-1"].text && (
                  <div className="mobile">
                    <TextInfo
                      {...this.props["TextMobile-1"]}
                      cyData="SimplePhone TextMobile-1"
                    />
                  </div>
                )}
            </>
          )}
          <div className="action-container">
            {this.props["Phone-1"] && (
              <div className="desktop">
                <CustomLink
                  href={href}
                  taggingInfo={taggingInfoPhones}
                  cyData="SimplePhone Desktop Phone"
                >
                  <TextInfo
                    {...this.props["Phone-1"]}
                    cyData="SimplePhone Desktop Phone Text"
                  />
                </CustomLink>
              </div>
            )}
            {this.props["Button-1"] && this.props["Button-1"]?.label ? (
              <div
                className={`${belowPrice ? "mobile-button-full" : "mobile"}`}
              >
                <Button
                  className="button-phone"
                  href={href}
                  taggingInfo={taggingInfoPhones}
                  cyData="SimplePhone Mobile Phone"
                  {...this.props["Button-1"]}
                />
              </div>
            ) : (
              this.props["Phone-1"] && (
                <div className="mobile">
                  <CustomLink
                    href={href}
                    taggingInfo={taggingInfoPhones}
                    cyData="SimplePhone Desktop Phone"
                  >
                    <TextInfo
                      {...this.props["Phone-1"]}
                      cyData="SimplePhone Desktop Phone Text"
                    />
                  </CustomLink>
                </div>
              )
            )}
          </div>
        </div>
        {(this.props["Glyph-1"].id || this.props["Text-2"].text) && (
          <div className="schedule-container" data-cy="SimplePhone Schedule">
            {this.props["Glyph-1"] && <Glyph {...this.props["Glyph-1"]} />}
            {this.props["Text-2"] && (
              <TextInfo
                {...this.props["Text-2"]}
                cyData="SimplePhone Text-2 Schedule"
              />
            )}
          </div>
        )}

        {this.props["C2C-Modal"]?.["Text-1"].text &&
          this.props["C2C-Modal"]?.["Text-2"].text && (
            <C2CModalComponent {...this.props["C2C-Modal"]} />
          )}
        <style jsx>{`.simple-phone-component {
  display: flex;
  flex-direction: column;
  position: relative;

  &.simplephone-reverse {
    flex-direction: column-reverse;
    gap: 0.5rem;
  }

  .mainPhoneCTA {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  .schedule-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-top: 0.5rem;
  }
}

.display-none-simplephone {
  display: none;
}

.simple-phone-component.big-phone {
  .mainPhoneCTA {
    display: block;
  }

  .mobile {
    :global(.text) {
      font-size: 16px;
    }
  }

  .action-container {
    .desktop {
      :global(.next-link) {
        cursor: inherit;
      }

      :global(.text) {
        font-size: clamp(1.25rem, 2.5vw, 1.75rem);
      }
    }
  }
}

@media screen and (max-width: 868px) {
  .simple-phone-component {
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .action-container {
      width: 100%;
    }
    .mainPhoneCTA {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-top: 0.5rem;
      gap: 1rem;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;

      :global(.text) {
        font-size: 18px;
      }
    }

    :global(.button-phone) {
      background-color: transparent !important;
    }

    .schedule-container {
      .sentence {
        display: block;
      }
    }
  }
}
`}</style>
      </div>
    );
  }
}

export default SimplePhone;
export { C2CModalComponent };
