import React from "react";
import TextInfo from "@/bit/components/components.text";
import CustomLink from "@/bit/components/components.custom-link";

const Link = (props) => {
  const { taggingInfo, onClick } = props;
  return (
    <>
      <div className="container-C2C">
        <TextInfo
          {...props["Text-1"]}
          cyData="SimplePhone Text C2C"
          className="C2C-description-text"
        />
        <CustomLink
          onClick={onClick}
          taggingInfo={taggingInfo}
          cyData="SimplePhone Text C2C"
        >
          <TextInfo {...props["Text-2"]} className="C2C-modal-button" />
        </CustomLink>
        <div className="separator" />
      </div>
      <div className="c2c-modal-link-separator" />
      <style jsx>{`.container-C2C {
  display: flex;
  flex-flow: wrap;
  column-gap: 0.3rem;
  align-items: center;
  width: 100%;

  :global(.text) {
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
  }
}
`}</style>
    </>
  );
};

export default Link;
