import React from "react";
import Title from "../title";
import CharacteristicsItems from "../characteristic-items";

const MainCharacteristics = (props) => {
  return (
    <div>
      <Title {...props} />
      <div
        className="main-characterisctics"
        data-cy={(props && props.cyData) || ""}
      >
        <CharacteristicsItems {...props} />
      </div>
      <style jsx>{`.main-characterisctics {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

@media screen and (min-width: 768px) {
  .main-characterisctics {
    gap: 0.2rem;
  }
}
`}</style>
    </div>
  );
};

export default MainCharacteristics;
