import React from "react";
import TextInfo from "@/bit/components/components.text";

const Title = (props) => {
  const dataCy = `Characteristics-Title ${
    props?.index !== undefined ? `Characteristics-Title-${props.index}` : ""
  }`;

  return (
    <>
      {props["Text-1"] && props["Text-1"].text && (
        <div
          className={`main-characterisctics--title ${props?.className}`}
          data-cy={dataCy}
        >
          <TextInfo {...props["Text-1"]} cyData="MainCharacteristic Title" />
        </div>
      )}
      <style jsx>{`.main-characterisctics--title {
  margin-bottom: 1rem;
}
`}</style>
    </>
  );
};

export default Title;
