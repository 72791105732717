import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledPoint = styled.div.attrs((props) => {
  return {
    "data-cy": `${props.cyData || ""}`,
  };
})`
  .point {
    width: ${(p) => p.radius}px;
    height: ${(p) => p.radius}px;
    background-color: ${(p) => (p.isActive ? "#019DF4" : "#DDDDDD")};
    transform: scale(${(p) => (p.isActive ? 120 : 100)}%);
  }
`;

const Point = ({ onClick, isActive, radius, cyData }) => {
  return (
    <StyledPoint radius={radius} isActive={isActive}>
      <div className="point" onClick={onClick} data-cy={cyData}></div>
      {/* INJECT_STYLED_JSX_TAG */}
    </StyledPoint>
  );
};

Point.propTypes = {
  /**
   * {
   *  "info": "Función que se ejecuta al clicar en el elemento pasando su index como argumento",
   *  "kind": "both",
   *  "beautifulName": "Función al clicar",
   * }
   */
  onClick: PropTypes.func,

  /**
   * {
   *  "info": "Index del punto dentro de su lista",
   *  "kind": "both",
   *  "beautifulName": "Index del punto",
   * }
   */
  i: PropTypes.number,

  /**
   * {
   *  "info": "Booleano que indica si el punto está activo y cambia sus estilos en consecuencia",
   *  "kind": "both",
   *  "beautifulName": "Punto activo",
   * }
   */
  isActive: PropTypes.bool,

  radius: PropTypes.number,
};

Point.defaultProps = {
  onClick: () => {},
  i: 0,
  isActive: false,
  radius: 8,
};

export default Point;
