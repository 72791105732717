import React from "react";
import PropTypes from "prop-types";
import TextInfo from "@/bit/components/components.text";
import Image from "@/bit/components/components.c-image";
import Carousel from "@/bit/components/components.carousel";
import Button from "@/bit/components/components.button";
import CustomLink from "@/bit/components/components.custom-link";

const RecomCharacteristics = (props) => {
  let cardValues = [];
  if (props && props?.cards) {
    const cardKeys = Object.keys(props.cards).filter((key) =>
      /^Card-\d+$/.test(key)
    );
    cardValues = cardKeys.map((key) => props.cards[key]);
  } else {
    const cardKeys = Object.keys(props).filter((key) => /^Card-\d+$/.test(key));
    cardValues = cardKeys.map((key) => props[key]);
  }

  const taggingInfoButtonConfigura = {
    componentName: "recom-characterisctics",
    componentVariation: "all",
    componentContent:
      props["Button-1"] && props["Button-1"].label
        ? props["Button-1"].label
        : "",
    componentProduct: props.alias || "",
    eventType: "click",
  };

  if (cardValues.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: props?.backgroundColor || "white",
        marginTop: props?.backgroundColor ? "1.5rem" : "",
      }}
    >
      <section
        className="recom-characterisctics container"
        data-cy="Section RecomCharacterisctics"
      >
        {props.name === "MODULO_RECOMENDACION" && !props.notSeparator && <hr className="separator" />}
        {props["Text-1"] && props["Text-1"].text && (
          <div className="recom-characterisctics--title">
            <TextInfo
              {...props["Text-1"]}
              kind="h"
              level="2"
              cyData="RecomCharacteristics Title"
            />
            {props["Button-1"] && (
              <Button
                className={`button-configura-desk${
                  props.alias === "Configura-tv-r" ? " button-tv-hide" : ""
                }`}
                {...props["Button-1"]}
                taggingInfo={taggingInfoButtonConfigura}
                cyData="RecomCharacteristics Button-1"
                type="primary"
              />
            )}
          </div>
        )}
        {cardValues && Array.isArray(cardValues) && cardValues.length > 0 && (
          <div
            className="recom-characterisctics--content"
            data-cy="RecomCharacterisctics Content"
          >
            {props.layout === "grid" ? (
              <div className="layout-grid">
                {cardValues.map((charac, index) => {
                  const cyData = `${
                    charac.categories &&
                    Array.isArray(charac.categories) &&
                    charac.categories.map((cat) => `${cat} `)
                  }`;
                  return (
                    <div
                      className="recom-characterisctics--item"
                      key={`recom-characterisctics--${index}`}
                      data-cy={`RecomCharacterisctics Card ${cyData}`}
                    >
                      <div className="recom-characterisctics--item--image">
                        <Image
                          fill
                          objectFit="cover"
                          {...charac["Image-1"]}
                          cyData="RecomCharacteristics Image-1"
                        />
                      </div>
                      <div className="recom-characterisctics--item--text">
                        {charac["Text-1"] && (
                          <TextInfo
                            {...charac["Text-1"]}
                            kind="h"
                            level="3"
                            className="recom-characterisctics--item--title"
                            cyData="RecomCharacteristics Text-1"
                          />
                        )}
                        {charac["Text-2"] && (
                          <TextInfo
                            {...charac["Text-2"]}
                            className="recom-characterisctics--item--description"
                            cyData="RecomCharacteristics Text-2"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Carousel
                className={props.carouselClassName || ""}
                maxPointNum={0}
                breakpoint={768}
              >
                {cardValues.map((charac, index) => (
                  <div
                    className="recom-characterisctics--item item-carousel"
                    key={`recom-characterisctics--${index}`}
                    data-cy="RecomCharacterisctics Card"
                  >
                    <div className="recom-characterisctics--item--image">
                      <Image
                        fill
                        objectFit="cover"
                        {...charac["Image-1"]}
                        cyData="RecomCharacteristics Image-1"
                      />
                    </div>
                    <div className="recom-characterisctics--item--text">
                      {charac["Text-1"] && (
                        <TextInfo
                          {...charac["Text-1"]}
                          kind="h"
                          level="3"
                          className="recom-characterisctics--item--title"
                          cyData="RecomCharacteristics Text-1"
                        />
                      )}
                      {charac["Text-2"] && (
                        <TextInfo
                          {...charac["Text-2"]}
                          className="recom-characterisctics--item--description"
                          cyData="RecomCharacteristics Text-2"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        )}
      </section>
      <style jsx>{`
        
        @media screen and (max-width: 768px) {
          :global(.button-tv-hide) {
            display: none;
          }
        }
        .recom-characterisctics {
          display: flex;
          flex-direction: column;
          padding-bottom: 0.5rem;

          &--title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          &--content {
            width: 100%;
            .layout-grid {
              display: grid;
              gap: 24px;
              grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
          :global(.carousel) {
            :global(.carousel-window) {
              gap: 1.5rem;
            }
          }

          &--item {
            display: flex;
            flex-direction: column;
            height: auto;
            border-radius: 16px;
            overflow: hidden;
            border: 1px solid #dddddd;
            min-width: 316px;
            &--text {
              padding: 1rem;
              display: flex;
              flex-direction: column;
              gap: 8px;
              :global(a) {
                cursor: default;
              }
              :global(.recom-characterisctics--item--title) {
                line-height: 1.5;
              }
              :global(.recom-characterisctics--item--description) {
                line-height: 1.5;
              }
            }
            &--image {
              position: relative;
              min-height: 13.5rem;
              width: 100%;
              padding-top: 75%;
            }
          }

          .item-carousel {
            width: min(20.9vw, 352px);
            height: 100%;
          }
          hr.separator {
            color: #dddddd;
            margin-top: 2rem;
            margin-bottom: 1rem;
          }
          @media screen and (max-width: 1024px) {
            .item-carousel {
              width: 20rem;
            }
          }
          @media screen and (max-width: 768px) {
            &--title {
              flex-direction: column;
              gap: 1rem;
              :global(.button-configura-desk) {
                width: 100%;
              }
            }
            :global(.carousel) {
              :global(.carousel-window) {
                gap: 0.25rem;
              }
            }
            .item-carousel {
              width: 20rem;
              height: 100%;
            }
            .layout-grid {
              display: flex;
              gap: 24px;
              overflow-x: auto;
              overflow-y: hidden;
            }
          }
        }
      `}</style>
    </div>
  );
};

RecomCharacteristics.propTypes = {};

RecomCharacteristics.defaultProps = {};

export default RecomCharacteristics;
