import React from "react";
import PropTypes from "prop-types";
import Glyph from "@/bit/components/components.icon-glyph";
import styled from "styled-components";

const Arrow = ({ direction, onClick, radius, cyData, className }) => {
  const onClickHandler = () => {
    onClick();
  };

  const finalClassName = `arrow-container arrow-container--${direction} ${className}`;

  return (
    <div
      className={finalClassName}
      data-cy={cyData}
      onClick={onClickHandler}
      style={{
        width: `${radius}px`,
        height: `${radius}px`,
      }}
    >
      {direction === "left" && (
        <Glyph id="cheuron-left-m" width={radius * 0.3} color="GREY" />
      )}
      {direction === "right" && (
        <Glyph id="cheuron-right-m" width={radius * 0.3} />
      )}
      <style jsx>{`.arrow-container {
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  border: solid gray 1px;
  background-color: white;
  position: absolute;
  top: 50%;
  z-index: 4;

  &--left {
    transform: translate(-50%, -50%);
    left: 0;
  }

  &--right {
    transform: translate(50%, -50%);
    right: 0;
  }

  &:hover {
    cursor: pointer;
  }
}
`}</style>
    </div>
  );
};

Arrow.propTypes = {
  /**
   * {
   *  "info": "Dirección de la flecha: left o right",
   *  "kind": "both",
   *  "beautifulName": "Dirección de la flecha",
   * }
   */

  direction: PropTypes.oneOf(["left", "right"]),

  /**
   * {
   *  "info": "Función que se ejecuta cuando se clica en el elemento",
   *  "kind": "both",
   *  "beautifulName": "Función al clicar",
   * }
   */
  onClick: PropTypes.func,

  /**
   * {
   *  "info": "Booleano para desplegar el componente o no",
   *  "kind": "both",
   *  "beautifulName": "Estilos custom",
   * }
   */
  display: PropTypes.bool,

  radius: PropTypes.number,
};

Arrow.defaultProps = {
  direction: "left",
  onClick: () => {},
  display: true,
  radius: 40,
};

export default Arrow;
